
import { uniqueId } from 'lodash';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'

@Component
export default class FInput extends Vue {

  @Prop({ default: () => 'input'+uniqueId()})
  id!: string;

  @Prop({ default: 'text' })
  type!: string;

  @Prop({ default: '' })
  label!: string;

  @Prop({ default: false, type: Boolean })
  required!: boolean;

  @Prop({ default: false, type: Boolean })
  hiddenLabel!: boolean;

  @Prop({ default: false, type: Boolean })
  search!: boolean;

  @Prop({ default: '', })
  placeholder!: string;

  @Prop()
  value !: string;

  @Prop({ default: null })
  min !: string | null;

  @Prop({ default: null })
  max !: string | null;

  @Prop({ default: false, type: Boolean })
  disabled!: boolean;

  @Prop({ default: null })
  minlength !: string | null;

  @Prop({ default: null })
  maxlength !: string | null;

  @Prop({ default: null })
  step !: string | null;

  @Prop({ default: ''})
  customErr!: string;

  @Prop({ default: () => null})
  pattern!: string | null;

  @Prop({ default: false, type: Boolean})
  busy!: boolean;

  @Prop({ default: '' })
  helpText!: string;

  @Prop({ default: false, type: Boolean})
  forceShowErrors!: boolean;

  @Prop({ default: false, type: Boolean})
  forceHideErrors!: boolean;

  @Prop({default: false, type: Boolean})
  preventSubmitForm!: boolean;

  @Watch('customErr')
  onCustomErrChange() {
    if (!this.customErr){
      (this.$refs.input as HTMLInputElement).setCustomValidity('');
    }else{
      (this.$refs.input as HTMLInputElement).setCustomValidity(this.customErr);
    }
  }

  error = '';

  get val() {
    return this.value;
  }

  set val(value){
    this.$emit('input', value);
  }

  mounted(){
    this.onInput();
    this.onCustomErrChange();
  }

  checkValidity(){
    return (this.$refs.input as HTMLInputElement).checkValidity();
  }

  @Watch('val')
  onInput(){
    this.error = (this.$refs.input as HTMLInputElement).validationMessage;
  }

  onChange(e:Event){
    this.$emit('change', e);

  }
  onBlur(e: Event) {
    this.$emit('blur', e);
  }
  onFocus(e: Event) {
    this.$emit('focus', e);
  }

  onKeyDown(e: KeyboardEvent){
    if (e.key.toLowerCase() === 'enter'){
      this.$emit('enter', e);
      if (this.preventSubmitForm){
        e.preventDefault();
        return false;
      }
    }

  }
}
