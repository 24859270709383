import { CatalogDetails } from '@/models/CatalogDetails';
import { SearchCatalogData } from '@/models/SearchCatalogData';
import router from '@/router';
import { Component, Vue } from 'vue-property-decorator'
import { ApiService } from './ApiService';
import { BusyService } from './BusyService';
import { TerritoryService } from './TerritoryService';


@Component
class SearchCatalogServiceClass extends Vue {
  searchResults: CatalogDetails[] | null = null;

  numResults = 0;
  query = { 
    page: 1, 
    searchText: '', 
    numPerPage: 10
  }

  setQueryParams(){
    const data: {[key: string]: string[] | string} = {}
    if (this.query.searchText){
      data.s = this.query.searchText;
    }

    if (this.query.page > 1){ 
      data.page = this.query.page.toString();
    }

    if (this.query.numPerPage != 10){ 
      data.numperpage = this.query.numPerPage.toString();
    }

    router.push({ path: '/intellectualproperty/catalogs', query: data}).catch((err)=>{});
  }

  getQueryParams(){ 
    if (router.currentRoute.query.s){
      this.query.searchText = router.currentRoute.query.s as string;
    }else{ 
      this.query.searchText = '';
    }
    
    if (router.currentRoute.query.numperpage){
      this.query.numPerPage = parseInt(router.currentRoute.query.numperpage as string);
    }else{ 
      this.query.numPerPage = 10;
    }
    
    if (router.currentRoute.query.page) { 
      this.query.page = parseInt(router.currentRoute.query.page as string) 
    }else{ 
      this.query.page = 1;
    }
  }

  onSetTerritory() { 
    this.query.page = 1;
    this.setQueryParams();
    this.performSearch();
  }

  updateSearchText(text: string){
    this.query.searchText = text;
    this.query.page = 1;
    this.setQueryParams();
  }

  setPage(page: number) { 
    this.query.page = page;
    this.setQueryParams();
  }

  setNumPerPage(numPerPage: number) { 
    this.query.numPerPage = numPerPage;
    this.query.page = 1;
    this.setQueryParams();
  }

  
  async performSearch() {     
    BusyService.showBusy();
    // sample api call when endpoint is ready
    const response = await ApiService.post('/api/GetCatalogs', { 
      territoryId: parseInt(TerritoryService.territoryId), 
      search: this.query.searchText, 
      page: this.query.page || 1, 
      pageSize: this.query.numPerPage || 10,
    }) as SearchCatalogData;

    this.searchResults = response.searchResults;
    this.numResults = response.numResults;
    BusyService.hideBusy();
  }
}

export const SearchCatalogService = new SearchCatalogServiceClass()
